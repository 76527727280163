import React, { Component } from "react"
import { Link } from "gatsby"
import "../Style/style.scss"
import Header from "../components/header"
import Card from "../components/card"
import MoviePoster from "../images/movie-poster.png"
import { Embed } from "semantic-ui-react"
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
} from "react-share"
import { Dimmer, Loader } from "semantic-ui-react"
import Poster from "../images/poster.png"
import BackgroundImage from "../components/BackgroundImage"

class ResultPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reveal: false,
      shuffling: true,
      loading: true,
    }
  }

  loadData() {
    var promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve("Loaded.")
      }, 1000)
    })
    return promise
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const story = JSON.parse(localStorage.getItem("tarot_story"))

    this.setState({
      cards: story.cards,
      story: story.story,
    })

    setTimeout(
      function() {
        this.setState({
          removeText: true,
        })
      }.bind(this),
      7500
    )

    setTimeout(
      function() {
        this.setState({
          shuffling: false,
        })
      }.bind(this),
      8500
    )

    this.loadData().then(data => {
      this.setState({
        loading: false, // hide loader
      })
    })
  }

  render() {
    return (
      <div>
        {this.state.loading && (
          <Dimmer active>
            <Loader>
              <h3 className="heading--l">Loading</h3>
            </Loader>
          </Dimmer>
        )}
        <BackgroundImage>
          <div className="result-page">
            <Header />
            {this.state.shuffling && (
              <section className="cards__shuffling">
                <div
                  className={
                    "cards__shuffling__text " +
                    (this.state.removeText && "fadeOut")
                  }
                >
                  <h2 className="heading--l">
                    Be patient. <br /> The spirits are determining your fate
                    .&nbsp;.&nbsp;.
                  </h2>
                </div>
                <div className="cards">
                  <div className="card">
                    <div className="back"></div>
                  </div>
                  <div className="card">
                    <div className="back"></div>
                  </div>
                  <div className="card">
                    <div className="back"></div>
                  </div>
                  <div className="card">
                    <div className="back"></div>
                  </div>
                  <div className="card">
                    <div className="back"></div>
                  </div>
                  <div className="card">
                    <div className="back"></div>
                  </div>
                  <div className="card">
                    <div className="back"></div>
                  </div>
                  <div className="card">
                    <div className="back"></div>
                  </div>
                  <div className="card">
                    <div className="back"></div>
                  </div>
                  <div className="card">
                    <div className="back"></div>
                  </div>
                  <div className="card">
                    <div className="back"></div>
                  </div>
                  <div className="card">
                    <div className="back"></div>
                  </div>
                  <div className="card">
                    <div className="back"></div>
                  </div>
                  <div className="card">
                    <div className="back"></div>
                  </div>
                  <div className="card">
                    <div className="back"></div>
                  </div>
                  <div className="card">
                    <div className="back"></div>
                  </div>
                  <div className="card">
                    <div className="back"></div>
                  </div>
                  <div className="card">
                    <div className="back"></div>
                  </div>
                  <div className="card">
                    <div className="back"></div>
                  </div>
                  <div className="card">
                    <div className="back"></div>
                  </div>
                  <div className="card">
                    <div className="back"></div>
                  </div>
                  <div className="card">
                    <div className="back"></div>
                  </div>
                  <div className="card">
                    <div className="back"></div>
                  </div>
                  <div className="card">
                    <div className="back"></div>
                  </div>
                </div>
              </section>
            )}

            {!this.state.shuffling && (
              <section className="result__reading">
                <h2 className="heading--l">Your fate has been sealed</h2>
                <p className="body--l fade-in">
                  Touch a card to learn its meaning and then{" "}
                  <span>scroll down</span> to read your fortune.
                </p>

                <div className="cards-container">
                  {this.state.cards &&
                    this.state.cards.map((card, key) => (
                      <div key={key} className={"card-holder--" + key}>
                        <Card
                          title={card.title}
                          description={card.description}
                          id={key}
                        />
                      </div>
                    ))}
                </div>

                <div id="story" className="result__story">
                  <div className="wrapper">
                    <div className="row centered">
                      <div className="col col-10">
                        <div className="story">
                          <div className="border"></div>
                          <h3 className="heading--l">Your reading</h3>
                          <p className="body--xxl">{this.state.story}</p>
                        </div>
                      </div>
                      <div className="col col-7">
                        <div className="result__share">
                          <div className="result__share__inner">
                            <h3 className="heading--l">Tell your loved ones</h3>
                            <p className="body--l">
                              Now that Madame Arcati has sealed your fate, pass
                              on her details to your friends and family
                            </p>
                            <FacebookShareButton
                              className="facebook"
                              url="https://blithetarot.co.uk/"
                            >
                              <FacebookIcon size={48} />
                            </FacebookShareButton>
                            <TwitterShareButton
                              className="twitter"
                              title="I have been given the gift of clarity and understanding. Consult with Madame Arcati and let her divine the truth about your own path."
                              url="https://blithetarot.co.uk/"
                            >
                              <TwitterIcon size={48} />
                            </TwitterShareButton>
                            <img className="poster" src={Poster} alt="poster" />
                          </div>
                        </div>
                      </div>
                      <div className="col col-1 col-empty"></div>
                      <div className="col col-4">
                        <Link to={"/reading"} className="go-again">
                          <div className="go-again__inner">
                            <h3 className="heading--l">
                              Want another reading?
                            </h3>
                            <p className="body--l">
                              Consult Madame Arcati again at your own risk
                              .&nbsp;.&nbsp;.
                            </p>
                            <div className="go-again__footer">
                              <div className="go-again__footer__decor left"></div>
                              <div className="go-again__footer__decor right"></div>
                              <span className="go-again__link" to={"/reading"}>
                                get another reading
                              </span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="result__ending">
                  <div className="smoke_slider">
                    <div className="smoke"></div>
                  </div>
                  <div className="smoke_slider smoke_slider--2">
                    <div className="smoke"></div>
                  </div>
                  <div className="wrapper">
                    <div className="row centered">
                      <div className="col col-6 col-movie-poster">
                        <div className="movie-poster">
                          <div className="tarot-1"></div>
                          <img src={MoviePoster} alt="Movie poster" />
                          <div className="tarot-2"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="studiomade_link">
                    A <a href="https://studiomade.co/">Studiomade</a> concept
                  </p>
                </div>
              </section>
            )}
          </div>
        </BackgroundImage>
      </div>
    )
  }
}

export default ResultPage
