import React, { Component } from "react"
import Header from "../components/header"
import Category from "../components/category"
import * as Data from "../data.js"
import { Redirect } from "@reach/router"
import BackgroundImage from "../components/BackgroundImage"

class Reading extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      story: false,
      clicked: false,
      curtains: "open",
      animation: "none",
    }
  }

  // load all data
  loadData() {
    var promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve("Loaded.")
      }, 1000)
    })
    return promise
  }

  removeCards(cat) {
    this.setState({
      category: cat,
    })

    this.getReading(cat)
  }

  getReading(cat) {
    let story = []

    switch (cat) {
      case "wealth":
        story =
          Data.storiesWealth[
            Math.floor(Math.random() * Data.storiesWealth.length)
          ]
        break
      case "love":
        story =
          Data.storiesLove[Math.floor(Math.random() * Data.storiesLove.length)]
        break
      case "career":
        story =
          Data.storiesCareer[
            Math.floor(Math.random() * Data.storiesCareer.length)
          ]
        break
      default:
        story =
          Data.storiesCareer[
            Math.floor(Math.random() * Data.storiesCareer.length)
          ]
        break
    }

    localStorage.setItem("tarot_story", JSON.stringify(story))

    this.setState({
      redirect: true,
    })
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.loadData().then(data => {
      this.setState({
        loading: false, // hide loader
      })
    })
  }

  render() {
    if (this.state.redirect) return <Redirect to="result" noThrow />

    return (
      <BackgroundImage>
        <div className="page-readings">
          <Header active={true} />
          <section className="page-inner">
            <div className="text">
              <h2 className="heading--l">See what your future holds</h2>
              <p className="body--m">
                Want to see what the forces from above have in store for your
                future? Choose from one of the 3 categories to begin your
                reading.
              </p>
            </div>
            <div className="categories">
              <button
                className="category love"
                onClick={this.removeCards.bind(this, "love")}
              >
                <Category category="love" />
              </button>
              <button
                className="category wealth"
                onClick={this.removeCards.bind(this, "wealth")}
              >
                <Category category="wealth" />
              </button>
              <button
                className="category career"
                onClick={this.removeCards.bind(this, "career")}
              >
                <Category category="career" />
              </button>
            </div>
          </section>
        </div>
      </BackgroundImage>
    )
  }
}

export default Reading
